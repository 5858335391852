<template>
  <div class="page1">

    <infoList v-if="result" :list="contractBaseList" :result="result">合同信息</infoList>
    <infoList v-if="result" :list="basicBaseList" :result="result.tenantInfo">原始商户信息</infoList>

    <div class="title">变更商户信息</div>
    <EForm :formColumns="formColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true" :formData="form" ref="form"
           :searchFlag="false" :labelPosition="labelPosition" :labelWidth="labelWidth" :formRules="formRules"
           @getImgList="getImgList" @getChange="getChange">
    </EForm>

    <infoList v-if="result" :list="boothBaseList" :result="result.boothInfo">摊位信息</infoList>


    <el-row>
      <el-button type="primary" @click="saveData" size="small">确认变更</el-button>
      <el-button type="primary" @click="goBack" size="small">取消</el-button>
    </el-row>

  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import infoList from '@/components/infoList'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        proArray: [],
        formColumns: [
          {
            title: '商户名称',
            property: 'tenantName',
            type: 'input',
            placeholder: '商户名称',
            show: true,
            disabled:true
          },
          {
            title: '统一社会信用代码',
            property: 'socialCreditCode',
            type: 'input',
            placeholder: '请填写18位的统一社会信用代码',
            show: true,
            disabled:true
          },
          {
            title: '营业执照地址',
            property: 'businessLicenseAdress',
            type: 'input',
            placeholder: '如：XX省XX市XX县XX街道XX号',
            show: true,
            disabled:true
          },
          {
            title: '开户行及其账号',
            property: 'bankAccount',
            type: 'input',
            placeholder: '如：光大银行 XXXX XXXX XXXX XXXX',
            show: true,
            disabled:true
          },
          {
            title: '联系电话',
            property: 'phone',
            type: 'input',
            placeholder: '例如：0571-88888888',
            show: true
          },
          {
            title: '负责人姓名',
            property: 'signPerson',
            type: 'input',
            placeholder: '真实姓名',
            show: true
          },
          {
            title: '身份证号码',
            property: 'idCard',
            type: 'input',
            placeholder: '例如：286057188888888',
            show: true
          },
          {
            title: '经营范围',
            property: 'businessScopeId',
            type: 'select',
            options: [],
            show: true,
            multiple:true
          },
          {
            title: '主营类别',
            property: 'mainWareType',
            type: 'pzTree',
            options: [],
            show: true,
            multiple:true
          },
          {
            title: '商户类型',
            property: 'tenantType',
            type: 'select',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
              {
                label: '其他',
                value: 3
              },
            ]
          },
          {
            title: '负责人籍贯',
            property: 'birthPlace',
            type: 'input',
            placeholder: '籍贯',
            show: true
          },
          {
            title: '变更原因',
            property: 'changeReason',
            type: 'input',
            show: true
          },
          {
            title: '身份证照片',
            property: 'idCardPath',
            type: 'mixinUpload',
            show: true
          },
        ],
        licenceList: [],
        form: {
          contractId: '',
          tenantName: '',
          phone: '',
          signPerson: '',
          businessScopeId: [],
          mainWareType: [],
          idCard: '',
          tenantType: '',
          birthPlace: '',
          idCardPath: [],
          changeReason:''
        },
        dialogForm: {},
        contractBaseList: [
          {
            label: '合同编号',
            property: 'contractCode',
          },
          {
            label: '合同类型',
            property: 'contractTypeCn',
          },
        ], //合同信息
        basicBaseList: [//基本信息
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '统一社会信用代码',
            property: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            property: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            property: 'bankAccount',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '主营类别',
            property: 'mainWareType',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '录入人',
            property: 'createUserCn',
          },
          {
            label: '录入时间',
            property: 'createTime',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type:'img'
          },
        ],
        boothBaseList: [ //摊位信息
          {
            label: '摊位号',
            property: 'boothCode',
          },
          {
            label: '状态',
            property: 'contractStatusCn',
          },
          {
            label: '用途',
            property: 'boothUseName',
          },
          {
            label: '面积',
            property: 'areas',
          },
          {
            label: '摊位类型',
            property: 'boothTypeName',
          },
          {
            label: '区域',
            property: 'areaName',
          },
          {
            label: '位置',
            property: 'placeName',
          },
          {
            label: '主营业务',
            property: 'rentPurposeName',
          },
          {
            label: '生效日期',
            property: 'inDate',
          },
          {
            label: '到期期自',
            property: 'expireDate',
          },
          {
            label: '交费方式',
            property: 'payTypeCn',
          },
        ],
        result: null,
        formRules: {
          socialCreditCode: vxRule(true, '', "blur", "社会统一信用代码不能为空"),
          businessLicenseAdress: vxRule(true, '', "blur", "营业执照地址不能为空"),
          bankAccount: vxRule(true, '', "blur", "开户行及银行卡账号不能为空"),
          tenantName: vxRule(true, '', "blur", "商户名称不能为空"),
          phone: [vxRule(true, '', "blur", "联系电话不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==11, errMsg: "请输入11位正确的手机号"}})],
          signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
          idCard:[vxRule(true, '', "blur", "身份证号码不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
        },
      }
    },
    watch: {},
    created() {
      this.form.contractId = this.$route.query.contractId
      this.contractDetail()
      this.businessscopeconfigLabel()
      this.mainWareTypeLabel()
    },
    components: {ETable, EButton, EForm, infoList},
    beforeMount() {

    },
    methods: {
      getChange(prop) {
        if(prop == 'tenantType'){
          if(this.form.tenantType==3){
            this.formRules={
              socialCreditCode: vxRule(false, '', "blur", "社会统一信用代码不能为空"),
              businessLicenseAdress: vxRule(false, '', "blur", "营业执照地址不能为空"),
              bankAccount: vxRule(false, '', "blur", "开户行及银行卡账号不能为空"),
              tenantName: vxRule(true, '', "blur", "商户名称不能为空"),
              phone: [vxRule(true, '', "blur", "联系电话不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==11, errMsg: "请输入11位正确的手机号"}})],
              signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
              idCard:[vxRule(true, '', "blur", "身份证号码不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
            }
          }else{
            this.formRules={
              socialCreditCode: vxRule(true, '', "blur", "社会统一信用代码不能为空"),
              businessLicenseAdress: vxRule(true, '', "blur", "营业执照地址不能为空"),
              bankAccount: vxRule(true, '', "blur", "开户行及银行卡账号不能为空"),
              tenantName: vxRule(true, '', "blur", "商户名称不能为空"),
              phone: [vxRule(true, '', "blur", "联系电话不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==11, errMsg: "请输入11位正确的手机号"}})],
              signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
              idCard:[vxRule(true, '', "blur", "身份证号码不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
            }
          }
        }
      },
      getImgList(data){
        this.$set(this.form,'idCardPath',data)
      },
      // getChange(prop) {
      //   if(prop == 'mainWareType'){
      //     this.mainWareProTypeLabel()
      //   }else if(prop == 'mainWareProType'){
      //     // this.proArray = []
      //     // this.proArray = this.form.mainWareProType;
      //   }
      // },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          photoName: '',
        }
        this.$refs.form.$refs.form.resetFields()
      },
      onSummit(tenantCardPhotoName) {
        console.log(tenantCardPhotoName)
        this.photoInfoForm.tenantCardPhotoName = tenantCardPhotoName
        this.dialogVisible = false
        this.licenceList.push(this.photoInfoFormColumns)
      },
      //获取合同详情
      async contractDetail() {
        console.log(this.id)
        let res = await Http.contractDetail({id: this.form.contractId})
        if (res.code == 200) {
          this.result = res.data
          this.result.boothInfo.payTypeCn = res.data.payTypeCn
          this.form = res.data.tenantInfo
          this.form.idCardPath = this.form.idCardPath?this.form.idCardPath.split(','):[]
          // this.form.idCardPath=this.form.idCardPath.split(',')
          let arr=this.form.businessScopeId.split(',')
          arr.forEach((item,i)=>{
            arr[i]=Number(item)
          })
          this.form.businessScopeId=arr
          let arr_type=this.form.mainWareType.split(',')
          arr_type.forEach((item,i)=>{
            arr_type[i]=Number(item)
          })
          this.form.mainWareType=arr_type
          if(res.data.tenantInfo.tenantType==3){
            this.formRules={
              socialCreditCode: vxRule(false, '', "blur", "社会统一信用代码不能为空"),
              businessLicenseAdress: vxRule(false, '', "blur", "营业执照地址不能为空"),
              bankAccount: vxRule(false, '', "blur", "开户行及银行卡账号不能为空"),
              tenantName: vxRule(true, '', "blur", "商户名称不能为空"),
              phone: [vxRule(true, '', "blur", "联系电话不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==11, errMsg: "请输入11位正确的手机号"}})],
              signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
              idCard:[vxRule(true, '', "blur", "身份证号码不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
            }
          }
        }
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          console.log(object)
          if (valid) {
            this.contractChangeoSubmit()
          } else {
            return false;
          }
        })
      },
      //更新合同
      async contractChangeoSubmit() {
        this.setDisabled(true)
        this.form.contractId = this.$route.query.contractId
        let form=JSON.parse(JSON.stringify(this.form))
        form.businessScopeId=form.businessScopeId.toString()
        form.mainWareType=form.mainWareType.toString()
        form.idCardPath=form.idCardPath.toString()
        let res = await Http.contractChange(form)
        if (res.code == 200) {
          if (this.licenceList.length == 0) {
            //没有证照信息直接返回
            this.$message.success(res.msg);
            this.$router.push({name: 'ContractList'})
            this.setDisabled(false)
          }
        }
      },
      //商户经营范围下拉
      async businessscopeconfigLabel() {
        let res = await Http.businessscopeconfigLabel({
          status:1
        })
        if (res.code == 200) {
          this.formColumns[7].options = res.data
        }
      },
      //主营商品类型
      async mainWareTypeLabel() {
        let res = await Http.mainWareTypeLabel({
          code:'mainWareType'
        })
        if (res.code == 200) {
          this.formColumns[8].options = res.data
        }
      },
    }
  }
</script>

<style lang="scss">
  .title {
    font-weight: bold;
    padding-left: 10px;
    border-left: 2px solid #4d68ff;
    margin-bottom: 20px;
  }

  .small-title {
    margin-left: 10px;
    margin-top: 20px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
</style>
